
const adminRoutes = [
  {
    path: "/dashboard",
    component: () =>
      import(/*webpackChunkName: 'group-admin' */ "@/pages/DashboardPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/belegungen",
    component: () =>
      import(/*webpackChunkName: 'group-admin' */ "@/pages/BelegungenPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/wohnungen",
    component: () =>
      import(/*webpackChunkName: 'group-admin' */ "@/pages/WohnungenPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

export default adminRoutes;
