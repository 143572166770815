<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12"><slot></slot></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "TheEmptyLayout",
  };
  </script>
  
  <style scoped>
  </style>