import axios from "axios";

const state = {
  wohnungen: [],
  belegungen: [],
  loading: false,
};

const mutations = {
  SET_WOHNUNGEN(state, wohnungen) {
    state.wohnungen = wohnungen;
  },
  SET_BELEGUNGEN(state, belegungen) {
    state.belegungen = belegungen;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  
  UPDATE_BELEGUNG(state, updatedBelegung) {
    const index = state.belegungen.findIndex(
      (b) => b.id === updatedBelegung.id
    );

    if (index !== -1) {
      state.belegungen[index] = updatedBelegung;
    }
  },
  ADD_WOHNUNG(state, wohnung) {
    state.wohnungen.push(wohnung);
  },
  ADD_BELEGUNG(state, belegung) {
    state.belegungen.push(belegung);
  },
};
const actions = {
  fetchWohnungen({ commit }) {
    commit("SET_LOADING", true);
    axios
      .get("https://junker.vogel.media/api/wohnungen")
      .then((response) => {
        commit("SET_WOHNUNGEN", response.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.log("Es gab ein Problem mit dem Abruf der Daten:", error);
        commit("SET_LOADING", false);
      });
  },
  fetchBelegungen({ commit }) {
    commit("SET_LOADING", true);
    axios
      .get("https://junker.vogel.media/api/belegungen")
      .then((response) => {
        commit("SET_BELEGUNGEN", response.data);
        commit("SET_LOADING", false);
        //console.log(response.data);
      })
      .catch((error) => {
        console.log("Es gab ein Problem mit dem Abruf der Daten:", error);
        commit("SET_LOADING", false);
      });
  },

  async updateBelegung({ commit }, belegung) {
    try {
      //axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST';

      const response = await axios.put(
        "https://junker.vogel.media/api/belegungen/" + belegung.id,
        belegung
      );
      commit("UPDATE_BELEGUNG", response.data);
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Belegung", error);
    }
  },

 


  async createWohnung({ commit }, wohnung) {
    try {
      const response = await axios.post(
        "https://junker.vogel.media/api/wohnungen",
        wohnung
      );
      commit("ADD_WOHNUNG", response.data);
    } catch (error) {
      console.error("Fehler beim Erstellen der Wohnung:", error);
    }
  },

  async createBelegung({ commit }, belegung) {
    try {
      const response = await axios.post(
        "https://junker.vogel.media/api/belegungen",
        belegung
      );
      commit("ADD_BELEGUNG", response.data);
    } catch (error) {
      console.error("Fehler beim Erstellen der Belegung:", error);
    }
  },
};

const getters = {
  Wohnungen: (state) => {
    return state.wohnungen;
  },
  Belegungen: (state) => {
    return state.belegungen;
  },
  isLoading: (state) => {
    return state.loading;
  },
  
};

const belegungenModule = {
  state,
  mutations,
  actions,
  getters,
};

export default belegungenModule;
