import * as Msal from "msal";
//import { MSAL_CONFIG } from "../../../config/msal";

const MSAL_CONFIG = {
  auth: {
    clientId: "0567c24e-f2f8-49b9-90cb-54b92a869905",
    authority: `https://login.microsoftonline.com/db08f51f-af36-4e88-86d9-4fcd73db34d7`,
    //redirectUri: "http://localhost:8080/auth/callback",
    redirectUri: "https://junker.vogel.media/auth/callback",
  },
};

const myMSALObj = new Msal.UserAgentApplication(MSAL_CONFIG);

const state = {
  userId: null,
  token: null,
  isAuthenticated: false,
};

const mutations = {
  setUser(state, payload) {
    state.userId = payload.userId;
    state.token = payload.token;
  },

  setAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },

  setAccessToken(state, payload) {
    state.token = payload;
  },
};

const actions = {
  /* signin(context) {
  
    console.log(MSAL_CONFIG);
    console.log(myMSALObj);

    myMSALObj
      .loginPopup({
        scopes: ["User.Read", "Tasks.Read"],
      })
      .then((response) => {
        console.log(response);

         context.commit("setUser", {
          userId: response.data.localId,
          token: response.data.idToken,
        }); 
        return  response.data.userName;
      })
      .catch((error) => {
        const errorMessage = new Error(
          error.response.data.error.message || "UNKNOWN_ERROR"
        );
        throw errorMessage;
      });

  }, */

  signin({ commit }) {
    return new Promise((resolve, reject) => {
      myMSALObj
        .loginPopup({
          scopes: ["User.Read", "Tasks.Read"],
        })
        .then((response) => {
          // Daten im LocalStorage speichern
          const expiresIn = Number(response.expiresOn) * 1000;
          // const expiresIn = 3 * 1000;
          const expDate = new Date().getTime() + expiresIn;

          localStorage.setItem("token", response.idToken.rawIdToken);
          localStorage.setItem("userId", response.account.userName);
          localStorage.setItem("expiresIn", expDate);

          console.log(response.expiresOn);
          commit("setAuthenticated", true);
          commit("setUser", response.account.userName);
          commit("setAccessToken", response.idToken.rawIdToken);
          resolve(response);
        })
        .catch((error) => {
          commit("setAuthenticated", false);
          reject(error);
        });
    });
  },

  autosignin(context) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    


    if (token && userId) {
      context.commit("setUser", {
        token: token,
        userId: userId,
      });
    }
  },


};

const getters = {
  isAuthenticated: (state) => !!state.token,
  token: (state) => state.token,
};

const authModule = {
  state,
  mutations,
  actions,
  getters,
};
export default authModule;
